import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {
    settings: null,
    loadingState: HTTP_STATUS.IDLE
}

export const fetchAppConfig = createAsyncThunk('app-config/get', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + '/api/AppConfig/get-app-config')
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const downloadBackup = createAsyncThunk('app-config/download-backup', async (data = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/AppConfig/download-backup`,{ responseType : 'blob' })
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Config = createSlice({
    name: 'config',
    initialState,
    reducers: {
        resetConfig: (state, action) => {
            return initialState
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAppConfig.fulfilled, (state, action) => {
                state.settings = action.payload
            })
            .addCase(downloadBackup.pending, (state, action) => {
                state.loadingState = HTTP_STATUS.LOADING
            })
            .addCase(downloadBackup.fulfilled, (state, action) => {
                state.loadingState = HTTP_STATUS.SUCCEEDED
            })
            .addCase(downloadBackup.rejected, (state, action) => {
                state.loadingState = HTTP_STATUS.FAILED
            })
    }
})

export const getConfig = (state) => state.config.settings
export const getloadingStatus = (state) => state.config.loadingState

export const { resetConfig } = Config.actions

export default Config.reducer