import { differenceInSeconds } from 'date-fns';
import { CAPACITY_MEASURMENT_CONVERT } from 'app/utils/constant';

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1)
  );
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export const replaceStringToNull = (j) => {
  for (var i in j){
      if (typeof j[i] === "object") {
        replaceStringToNull(j[i]);
      }
      if (j[i] === "null") {
          j[i] = JSON.parse(j[i]);
      }
      if (j[i] === "") {
        j[i] = JSON.parse(null);
      }
  }
  return j;
}

export const numberToCurrency = (input) => input !== null || input !== undefined ?
    Number(input) < 0 ? "-" + input?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }).substr(5) :
    input?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }).substr(4) :
    input;

export const numberToCommaSep = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const capacityCalculate = (value, qty, measurement) => {

  let amount = value * qty;

  if(qty === 1) return value + ' ' + CAPACITY_MEASURMENT_CONVERT[measurement];

  switch (CAPACITY_MEASURMENT_CONVERT[measurement]) {
      case 'MG': // Milligrams
          if (amount >= 1000000) {
              return `${(amount / 1000000)} KG`;
          } else if (amount >= 1000) {
              return `${(amount / 1000)} G`;
          } else if( amount > 0 ) {
              return `${amount} MG`;
          } else{
              return amount;
          }
      case 'G': // Grams
          if (amount >= 1000) {
              return `${(amount / 1000)} KG`;
          } else if( amount > 0 ) {
              return `${amount} G`;
          }else{
              return amount;
          }
      case 'KG': // Kilograms
          if(amount > 0){
            return `${amount} KG`;
          }else{
            return amount;
          }
      case 'ML': // Milliliters
          if (amount >= 1000) {
              return `${(amount / 1000)} L`;
          } else if (amount > 0){
              return `${amount} ML`;
          }else{
              return amount;
          }
      case 'L': // Liters
          if(amount > 0){
            return `${amount} L`;
          }else{
            return amount;
          }
      default:
          return `Invalid unit`;
  }

  // return ((value * qty) / 1000) + ' ' + CAPACITY_MEASURMENT_CONVERT[measurement];
}

export const matchLitersIntoMilliliters = (capacity, measurement) => {
  switch(CAPACITY_MEASURMENT_CONVERT[measurement]){
    case 'L':
      return capacity * 1000;
    case 'ML':
      return capacity;
    default:
        return -1;
  }
}

export const generateDownloadFileName = (disposition) => {
  let filename = "";
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) { 
        filename = matches[1].replace(/['"]/g, '');
    }
  }else{
    filename = "1234"
  }
  return filename;
}

export const downloadFile = (data, filename) => {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}
