import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Outlets = Loadable(lazy(() => import('./Outlet/Outlet')));
const OtherIncomeAccount = Loadable(lazy(() => import('./OtherIncomeAccount/OtherIncomeAcc')))
const Banks = Loadable(lazy(() => import('./Bank/Bank')));
const PettyCashCategory = Loadable(lazy(() => import('./PettyCashCategory/PettyCashCategory')));
const PettyCashItem = Loadable(lazy(() => import('./PettyCashItem/PettyCashItem')));

const stockRoutes = [
    { path: '/account-master/debtors',  element: <Outlets />, auth: authRoles.admin },  
    { path: '/account-master/debtors/:id',  element: <Outlets />, auth: authRoles.admin },  
    { path: '/account-master/bank',  element: <Banks />, auth: authRoles.admin },  
    { path: '/account-master/bank/:id',  element: <Banks />, auth: authRoles.admin },
    { path: '/account-master/other-income-acc',  element: <OtherIncomeAccount />, auth: authRoles.admin },  
    { path: '/account-master/other-income-acc/:id',  element: <OtherIncomeAccount />, auth: authRoles.admin },  
    { path: '/account-master/petty-cash-category',  element: <PettyCashCategory />, auth: authRoles.admin },  
    { path: '/account-master/petty-cash-category/:id',  element: <PettyCashCategory />, auth: authRoles.admin},
    { path: '/account-master/petty-cash-item',  element: <PettyCashItem />, auth: authRoles.admin },  
    { path: '/account-master/petty-cash-item/:id',  element: <PettyCashItem />, auth: authRoles.admin },
];

export default stockRoutes;