import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import { TR_TYPE_OPPOSIT } from 'app/utils/constant'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {    
    credits : [],
    credits_collected: [],
    credit_bills: [],
    credit_collected_by_date: [],
    pendingCreditReport: null, 
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchCreditsBySales = createAsyncThunk('credit/get-credit-by-sales', async ({salesId, data}, { rejectWithValue }) => {
    try {
        console.log(data);
        
        let url = new UrlBuilder().filter(data, 'trType').filter(data, 'paymentSource').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + `/api/Credit/get-by-sales/${salesId}${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchpendingCredits = createAsyncThunk('credit/get-pending-credits', async (data = null, { rejectWithValue }) => {
    let url = new UrlBuilder().searchQuery(data).filter(data, 'fromDate').filter(data, 'toDate').filter(data, 'id').filter(data, 'repId').filter(data, 'receivables').getUrl();
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Credit/get-pending-credits${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchPendingCreditReport = createAsyncThunk('credit/get-pending-credit-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Credit/pending-credit-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchCreditReport = createAsyncThunk('credit/get-credit-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').filter(data, 'bank')
                        .filter(data, 'billNo').filter(data, 'outletId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Credit/credit-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchCreditCollection = createAsyncThunk('credit/get-credit-collection', async (data = null, { rejectWithValue }) => {
    let url = new UrlBuilder().searchQuery(data).filter(data, 'date').filter(data, 'debtorId').getUrl();
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Credit/get-credit-collected${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createCredits = createAsyncThunk('credit/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Credit', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createBackdateCreditBill = createAsyncThunk('credit/create/back-date', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Credit/open-credit-bill', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const removeCredits = createAsyncThunk('credit/remove', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Credit/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})



export const Credit = createSlice({
    name: 'credit',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        resetCredits: (state, action) => {
            state.credits = [];
            return state
        },
        resetCreditBills: (state, action) => {
            state.credit_bills = [];
            return state
        },
        resetCreditCollected: (state, action) => {
            state.credits_collected = [];
            return state
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCreditsBySales.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchCreditsBySales.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.credits = action.payload?.filter(item => item?.tr_type === TR_TYPE_OPPOSIT.CREDIT)
                state.credits_collected = action.payload?.filter(item => item?.tr_type === TR_TYPE_OPPOSIT.DEBIT)
            })
            .addCase(fetchCreditsBySales.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchCreditCollection.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchCreditCollection.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.credit_collected_by_date = action.payload
            })
            .addCase(fetchCreditCollection.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(removeCredits.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(removeCredits.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(removeCredits.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchpendingCredits.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchpendingCredits.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.credit_bills = action.payload
            })
            .addCase(fetchpendingCredits.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchPendingCreditReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchPendingCreditReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pendingCreditReport = action.payload
            })
            .addCase(fetchPendingCreditReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchCreditReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchCreditReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pendingCreditReport = action.payload
            })
            .addCase(fetchCreditReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            
    }
})

export const getCredits = (state) => state.credit.credits;
export const getCreditCollected = (state) => state.credit.credits_collected;
export const getCreditCollectedByDate = (state) => state.credit.credit_collected_by_date;
export const getCreditBills = (state) => state.credit.credit_bills;
export const getCreditsStatus = (state) => state.credit.status;
export const getDeleteCreditStatus = (state) => state.credit.deleteStatus;
export const getPendingCreditReport = (state) => state.credit.pendingCreditReport;
export const creditActions = Credit.actions

export default Credit.reducer