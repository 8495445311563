import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import UrlBuilder from 'app/utils/UrlBuilder'

const initialState = {    
    accounts : [],
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchAccounts = createAsyncThunk('account/get-expence', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().searchQuery(data).filter(data, 'acc_type').getUrl();
        const response = await newAxiosInstance.get(BASE_URL + '/api/Account' + url ) 
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createAccount = createAsyncThunk('accounts/create', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Account', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateAccount = createAsyncThunk('accounts/update', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/Account/${postdata.id}`, postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const deleteAccount = createAsyncThunk('accounts/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Account/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const Account = createSlice({
    name: 'account',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAccounts.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchAccounts.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.accounts = action.payload
            })
            .addCase(fetchAccounts.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(deleteAccount.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(deleteAccount.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
    }
})

export const getAccounts = (state) => state.account.accounts;
export const getDeletestatus = (state) => state.account.deleteStatus;

export const accountActions = Account.actions

export default Account.reducer